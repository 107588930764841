<template>
  <div id="content">
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDark"
      @click="checkMode(isDark)"
    >
      {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import store from '@/store';

export default {
    methods: {
        checkMode(value){
            // console.log(value,this.isDark);
            store.set('accounts/darkMode', value);
            return this.isDark = !this.isDark;
        }
    },
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style scoped>
@media print {
    body * {
        display: block;
    }
    #content, #content * {
        display: none;
    }
}
</style>
