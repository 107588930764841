// import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import printJS from 'print-js';
import Vue from 'vue';
import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import App from './App.vue';
import './plugins/acl';
import mask from './plugins/mask';
import sweetalert2 from './plugins/sweet-alert';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  PhoneNumberInput,
  printJS,
  vuetify,
  sweetalert2,
  mask,
  render: h => h(App),
}).$mount('#app')
