<template>
    <div>
        <loader :visible="loading"></loader>
        <component :is="resolveLayoutVariant"
                   :class="`skin-variant--${appSkinVariant}`">
            <transition :name="appRouteTransition"
                        mode="out-in"
                        appear>
                <div>
                    <router-view :loading="loading"
                                 @loading-updated="updateLoading" />

                </div>
            </transition>
        </component>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

import loader from './views/loader.vue'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import useDynamicVh from '@core/utils/useDynamicVh'

import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import store from './store'
// Dynamic vh

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutBlank,
        loader
    },
    data()
    {
        return {
            loading: false,
        };
    },
    created()
    {

        store.subscribe((mutation) =>
        {

            if (mutation.type === 'accounts/SET_LOADER')
            {
                console.log('came here')
                this.loading = mutation.payload;
            }


        })
    },
    methods: {
        updateLoading(loading)
        {

            this.loading = loading;
        },
    },

    setup()
    {
        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() =>
        {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

            return null
        })

        useDynamicVh()

        return {
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
}
</script>
