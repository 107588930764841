import { mdiAccountBox, mdiAccountMultiple, mdiCalendarMonth, mdiClipboardText, mdiViewDashboard, mdiWallet } from '@mdi/js';

let role_name = localStorage.getItem('role_name');

let admin = [
    { subheader: 'MENU' },
    { title: 'Dashboard', icon: mdiViewDashboard, to: 'dashboard' },
    { title: 'Bookings', icon: mdiCalendarMonth, to: 'booking-list' },
    { title: 'Boat Registration', icon: mdiClipboardText, to: 'myboat-list' },
    { title: 'Packages', icon: mdiAccountBox, to: 'package-list' },
    {
        title: 'Users Management', icon: mdiAccountMultiple,
        children: [
            { title: 'Admins', to: 'admin-list', },
            { title: 'Boat Owners', to: 'owner-list', },
            { title: 'Boat Captains', to: 'captain-list', },
            { title: 'App Users', to: 'user-list', }
        ],
    },

    { title: 'Finance', icon: mdiWallet, to: 'finance-list' },
    { title: 'System Parameters', icon: mdiWallet, to: 'system-parameter' },
]

let boatOwner = [
    { subheader: 'MENU' },
    { title: 'Dashboard', icon: mdiViewDashboard, to: 'dashboard' },
    { title: 'Bookings', icon: mdiCalendarMonth, to: 'booking-list' },
    { title: 'Boat Registration', icon: mdiClipboardText, to: 'myboat-list' },
    { title: 'Packages', icon: mdiAccountBox, to: 'package-list' },
    {
        title: 'Users Management', icon: mdiAccountMultiple,
        children: [
            // { title: 'Admins', to: 'admin-list', },
            // { title: 'Boat Owners', to: 'owner-list', },
            { title: 'Boat Captains', to: 'captain-list', },
            // { title: 'App Users', to: 'user-list', }
        ],
    },
    // { title: 'Finance', icon: mdiWallet, to: 'finance-list' },
]

let subAdmin = [
    { subheader: 'MENU' },
    { title: 'Dashboard', icon: mdiViewDashboard, to: 'dashboard' },
    { title: 'Bookings', icon: mdiCalendarMonth, to: 'booking-list' },
    { title: 'Boat Registration', icon: mdiClipboardText, to: 'myboat-list' },
    { title: 'Packages', icon: mdiAccountBox, to: 'package-list' },
    {
        title: 'Users Management', icon: mdiAccountMultiple,
        children: [
            { title: 'Admins', to: 'admin-list', },
            { title: 'Boat Owners', to: 'owner-list', }
        ],
    },
    { title: 'Finance', icon: mdiWallet, to: 'finance-list' },
    { title: 'System Parameters', icon: mdiClipboardText, to: 'system-parameter' },
]

let initiator = [
    { subheader: 'MENU' },
    {
        title: 'Boat Registration', icon: mdiClipboardText,
        children: [
            { title: 'List', to: 'myboat-list', },
            { title: 'Add', to: { name: 'myboat-add' } }
        ],
    },
]

let approver = [
    { subheader: 'MENU' },
    {
        title: 'Boat Registration', icon: mdiClipboardText,
        children: [
            { title: 'List', to: 'myboat-list' }
        ],
    },
]
let menus = [];

console.log(role_name);

if (role_name == 'admin')
{
    menus = admin
}
else if (role_name == 'sub_admin')
{
    menus = subAdmin
}
else if (role_name == 'initiator')
{
    menus = initiator
}
else if (role_name == 'boat_owner')
{
    menus = boatOwner
}
else
{
    menus = approver
}

export default menus
