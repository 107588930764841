import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import createPersistedState from 'vuex-persistedstate';

import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import app from './app';

import accounts from './modules/accounts';
import boatRegistrations from './modules/boatRegistration';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [pathify.plugin, createPersistedState({ storage: window.localStorage, paths: ['accounts', 'boatRegistrations'] })],
  modules: {
    appConfig: appConfigStoreModule,
    app,
    accounts,
    boatRegistrations
  },
});

export default store;


// import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
// import Vue from 'vue'
// import Vuex from 'vuex'
// import app from './app'

// Vue.use(Vuex)

// const store = new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {
//     appConfig: appConfigStoreModule,
//     app,
//   },
// })

// export default store;