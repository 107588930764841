
const apps = [
  {
    path: '/boat/list',
    name: 'myboat-list',
    component: () => import('@/views/myboat/myboat-list/MyboatList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/myboat/add',
    name: 'myboat-add',
    component: () => import('@/views/myboat/myboat-add/MyboatAdd.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/myboat/:type/:id',
    name: 'myboat-update',
    component: () => import('@/views/myboat/myboat-add/MyboatAdd.vue'),
    meta: {
      layout: 'content',
    },
  },
  //
  //* ——— User ——————————————————
  //

  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user/:id',
    name: 'user-view',
    component: () => import('@/views/user/user-list/ViewApp.vue'),
    meta: {
      layout: 'content',
    },
  },

  // ADMIN/////////////

  {
    path: '/admin/list',
    name: 'admin-list',
    component: () => import('@/views/user/admin/AdminList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/update/:id',
    name: 'admin-update',
    component: () => import('@/views/user/admin/AdminForm.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/add/admin',
    name: 'admin-add',
    component: () => import('@/views/user/admin/AdminForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/:id',
    name: 'admin-view',
    component: () => import('@/views/user/user-list/ViewUser.vue'),
    meta: {
      layout: 'content',
    },
  },

  /// / owner//////////

  {
    path: '/owner/list',
    name: 'owner-list',
    component: () => import('@/views/user/boatowner/BoatOwnerList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/owner/:id',
    name: 'owner-view',
    component: () => import('@/views/user/boatowner/ViewOwner.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/add/owner',
    name: 'owner-add',
    component: () => import('@/views/user/user-list/UserListAddNew.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/update/owner/:id',
    name: 'owner-update',
    component: () => import('@/views/user/user-list/UserListAddNew.vue'),
    meta: {
      layout: 'content',
    },
  },

    /// / captain//////////

    {
        path: '/captain/list',
        name: 'captain-list',
        component: () => import('@/views/user/BoatCaptain/BoatCaptainList.vue'),
        meta: {
          layout: 'content',
        },
      },
    
      {
        path: '/captain/:id',
        name: 'captain-view',
        component: () => import('@/views/user/BoatCaptain/ViewBoatCaptain.vue'),
        meta: {
          layout: 'content',
        },
      },
    
      {
        path: '/add/captain',
        name: 'captain-add',
        component: () => import('@/views/user/BoatCaptain/BoatCaptainForm.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: '/update/captain/:id',
        name: 'captain-update',
        component: () => import('@/views/user/BoatCaptain/BoatCaptainForm.vue'),
        meta: {
          layout: 'content',
        },
      },

  //
  //* ——— Owner ——————————————————
  //

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },

  

  //
  //* ——— Booking ——————————————————
  //

  {
    path: '/booking/list',
    name: 'booking-list',
    component: () => import('@/views/booking/BookingList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/booking/:id',
    name: 'booking-view',
    component: () => import('@/views/booking/ViewBooking.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— System parameter ——————————————————
  //

  {
    path: '/system/parameter',
    name: 'system-parameter',
    component: () => import('@/views/systemparameter/SystemParameter.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Packages ——————————————————
  //
  {
    path: '/package/list',
    name: 'package-list',
    component: () => import('@/views/package/PackageListing.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/package/detail',
    name: 'package-add',
    component: () => import('@/views/package/PackageDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/package/detail/:type/:id',
    name: 'package-update',
    component: () => import('@/views/package/PackageDetail.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/MyProfile.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/finance/list',
    name: 'finance-list',
    component: () => import('@/views/finance/Finance.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/view-finance/:id',
    name: 'finance-view',
    component: () => import('@/views/finance/ViewFinance.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default apps
