<template>
    <div v-if="visible"
         class="loader">
        <div class="loader-img">
            <img style="width:122px;"
                 src="@/assets/images/loader-1.svg"
                 alt="">
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        visible: {
            default: false,
            type: Boolean
        }
    }
}
</script>
  
<style>
.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(224 224 224 / 10%);
    z-index: 100000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>