import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'

// import dashboard from './dashboard'

Vue.use(VueRouter)

const routes = [
    // ? We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
        path: '/',
        redirect: to =>
        {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const role = JSON.parse(localStorage.getItem('role'))
            const userRole = role && role.id ? role.id : null

            //   console.log(userRole)
            //   console.log(role && role.id)

            if (userRole === 1) return { name: 'myboat-list' }

            // if (userRole === 'client') return { name: 'page-access-control' }

            return { name: 'auth-login', query: to.query }
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    //   {
    //     path: '/register',
    //     name: 'auth-register',
    //     component: () => import('@/views/Register.vue'),
    //     meta: {
    //       layout: 'blank',
    //       resource: 'Public',
    //       redirectIfLoggedIn: true,
    //     },
    //   },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/otp/:type',
        name: 'one-time-passcode',
        component: () => import('@/views/auth/OneTimePasscode.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@/views/auth/TermsConditions.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/terms-and-conditions-ar',
        name: 'terms-and-conditions-ar',
        component: () => import('@/views/auth/TermsConditionsAr.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    ...apps,

    // {
    //   path: '*',
    //   redirect: 'error-404',
    // },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior()
    {
        return { x: 0, y: 0 }
    },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) =>
{
    const userData = localStorage.getItem('userData')

    const isLoggedIn = userData && localStorage.getItem('authorization')

    if (!canNavigate(to))
    {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

        // If logged in => not authorized
        // return next({ name: 'misc-not-authorized' })

        // return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
    {
        next('/dashboard')
    }

    return next()
})

export default router
