import { render, staticRenderFns } from "./VerticalNavMenu.vue?vue&type=template&id=8e3cb4a0&"
import script from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenu.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VerticalNavMenu.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})
